
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    :close-on-click-modal="false"
    style="text-align: left"
    width="80%"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'150px'"
        inline
      >
        <el-form-item label="购买方式" prop="name">
          <el-input
            v-model="formModel.name"
            placeholder="请输入购买方式"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="金额" prop="money">
          <el-input
            v-model="formModel.money"
            placeholder="请输入金额"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="序号" prop="sort">
          <el-input
            v-model="formModel.sort"
            placeholder="请输入序号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import parkingWayApi from "@/api/base/parkingWay";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        id: [{ required: true, message: "不能为空", trigger: "blur" }],
        companyId: [
          { required: true, message: "公司id不能为空", trigger: "blur" },
        ],
        name: [
          { required: true, message: "购买方式不能为空", trigger: "blur" },
        ],
        money: [{ required: true, message: "金额不能为空", trigger: "blur" }],
        sort: [{ required: true, message: "序号不能为空", trigger: "blur" }],
        delFlag: [
          { required: true, message: "是否删除不能为空", trigger: "blur" },
        ],
        createBy: [{ required: true, message: "不能为空", trigger: "blur" }],
        createTime: [{ required: true, message: "不能为空", trigger: "blur" }],
        updateBy: [{ required: true, message: "不能为空", trigger: "blur" }],
        updateTime: [{ required: true, message: "不能为空", trigger: "blur" }],
      },
      showDialog: true,
      loading: false,
      submitting: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return parkingWayApi.add(self.formModel);
            } else {
              return parkingWayApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return parkingWayApi.create();
      } else {
        return parkingWayApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>